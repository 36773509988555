import React from "react"
import PropTypes from "prop-types"

import * as s from "./Navigation.module.scss"

const Navigation = ({ sticky }) => (
  <nav className={[s.nav, sticky ? s.sticky : ""].join(" ")}>Nav principal</nav>
)

Navigation.defaultProps = {
  sticky: false,
}

Navigation.propTypes = {
  sticky: PropTypes.bool,
}

export default Navigation
