import React from "react"
import PropTypes from "prop-types"

import useAppContext from "@contexts/AppContext"
import Navigation from "@components/Navigation/Navigation"
import Footer from "@components/Footer/Footer"

const MainLayout = ({ children }) => {
  const {
    state: { navSticky },
  } = useAppContext()

  return (
    <>
      <div className="d-none IN-DEVELOPMENT">
        <Navigation sticky={navSticky} />
      </div>
      <main>{children}</main>
      <Footer />
    </>
  )
}

MainLayout.defaultProps = {}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
