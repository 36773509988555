import React, { useMemo } from "react"
// import PropTypes from "prop-types"

import * as s from "./Footer.module.scss"

const Footer = () => {
  const currentYear = useMemo(() => (new Date().getFullYear()), []);

  return (
    <footer className={s.container} id="footer">
      <span>&#169; Copyright {currentYear} Thibaut Trouvé</span>
      <span> - Made with Gatsby - Still under construction...</span>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
